import {APIService} from "../../../services/api";
import i18n from "@/plugins/i18n";

export const uniquerTokenValidator = (value, field, model) => {
  return new Promise(async (resolve, reject) => {
    if (value) {
      try {
        const tags = await APIService.get(`tag?filter=token||$eq||${value}`);
        if (tags.length > 0) {
          if (tags[0].id !== model.id) {
            resolve([ i18n.t('TagTokenExists{value}',{value})]);
          }
        } else {
          resolve();
        }
      } catch (e) {
        console.error(e)
        reject()
      }
    }
  });
};
