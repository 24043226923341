import {validators} from "vue-form-generator";
import {uniquerTokenValidator} from "../validations/uniqueToken";
import i18n from "@/plugins/i18n";

export const schema = {
  fields: [
    {
      type: "input",
      inputType: "text",
      label: "name",
      model: "name",
      required: true,
      placeholder: "name",
      max: 256,
      min: 4,
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("nameRequired"),
          textTooSmall: i18n.t("nameMustBeMin{1}"),
          textTooBig: i18n.t("nameMustBeLess{1}"),
        }),
      ]
    },
    {
      type: "input",
      inputType: "text",
      label: "token",
      model: "token",
      required: true,
      placeholder: "token",
      max: 256,
      validator: [
        uniquerTokenValidator,
        validators.string.locale({
          fieldIsRequired: i18n.t("tokenRequired"),
          textTooBig: i18n.t("tokenMustBeLess{1}"),
        }),
      ]
    },
    {
      type: "array",
      label: "tagExtraAttribute",
      model: "attributes",
      showRemoveButton: true,
      itemContainerClasses: "tag-attribute-form",
      newElementButtonLabelClasses: "btn btn-primary",
      newElementButtonLabel: i18n.t('addTag'),
      removeElementButtonClasses: "btn btn-danger",
      removeElementButtonLabel: i18n.t('removeTag'),
      validator: 'array',
      items: {
        type: 'object',
        default: {},
        schema: {
          fields: [
            {
              type: 'input',
              inputType: 'hidden',
              model: 'id',
            },
            {
              type: 'input',
              inputType: 'text',
              label: i18n.t('code'),
              model: 'code',
              validator: [
                validators.string.locale({
                  fieldIsRequired: i18n.t("codeRequired"),
                  textTooBig: i18n.t("codeMustBeLess{1}"),
                }),
              ],
            },
            {
              type: 'input',
              inputType: 'text',
              label: i18n.t('name'),
              model: 'name',
              validator: [
                validators.string.locale({
                  fieldIsRequired: i18n.t("nameRequired"),
                  textTooBig: i18n.t("nameMustBeLess{1}"),
                }),
              ],
            },
            {
              type: 'select',
              label: i18n.t('type'),
              model: 'type',
              required: true,
              selectOptions: {
                noneSelectedText: i18n.t("chooseTagType"),
              },
              values: [
                { name: i18n.t("Text"), id: 1 },
                { name: i18n.t("Number"), id: 2 },
                { name: i18n.t("yes/no"), id: 3 },
                { name: i18n.t("ChooseFromList"), id: 4 },
              ],
              validator: [
                validators.required.locale({
                  fieldIsRequired: i18n.t("typeRequired"),
                }),
              ],
            },
            {
              type: "array",
              model: "enums",
              label: i18n.t('tagEnums'),
              newElementButtonLabelClasses: "btn btn-primary",
              newElementButtonLabel: i18n.t('addTagEnum'),
              itemContainerClasses: "tag-attribute-enum-form",
              visible: (model) => {
                return model && model.type === 4;
              },
              required: (model) => {
                return model && model.type === 4;
              },
              items: {
                type: 'object',
                default: {},
                schema: {
                  fields: [
                    {
                      type: 'input',
                      inputType: 'hidden',
                      model: 'id',
                    },
                    {
                      type: 'input',
                      inputType: 'text',
                      label: i18n.t('code'),
                      model: 'code',
                      required: true,
                      max: 128,
                      validator: [
                        validators.string.locale({
                          fieldIsRequired: i18n.t("codeRequired"),
                          textTooBig: i18n.t("codeMustBeLess{1}"),
                        }),
                      ],
                    },
                    {
                      type: 'input',
                      inputType: 'text',
                      label: i18n.t('value'),
                      model: 'value',
                      required: true,
                      max: 128,
                      validator: [
                        validators.string.locale({
                          fieldIsRequired: i18n.t("valueRequired"),
                          textTooBig: i18n.t("valueMustBeLess{1}"),
                        }),
                      ],
                    },
                    {
                      type: "input",
                      inputType: "text",
                      label: "iconClass",
                      model: "iconClass",
                      required: true,
                      placeholder: "iconClass",
                      max: 25,
                      validator: [
                        validators.string.locale({
                          fieldIsRequired: i18n.t("iconClassRequired"),
                          textTooBig: i18n.t("iconClassMustBeLess{1}"),
                        }),
                      ]
                    },
                  ],
                },
              },
            }
          ]
        }
      }
    },
    {
      type: "checkbox",
      label: "allowTree",
      model: "allowTree",
      required: true,
    },
    {
      type: "checkbox",
      label: "allowSeo",
      model: "allowSeo",
      required: true,
    },
    {
      type: "checkbox",
      label: "isRequired",
      model: "isRequired",
      required: true,
    },
    {
      type: "input",
      inputType: "text",
      label: "iconClass",
      model: "iconClass",
      required: true,
      placeholder: "iconClass",
      max: 25,
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("iconClassRequired"),
          textTooBig: i18n.t("iconClassMustBeLess{1}"),
        }),
      ]
    },
    {
      type: "select",
      label: "status",
      model: "status",
      required: true,
      validator: [
        validators.required.locale({
          fieldIsRequired: i18n.t("statusRequired"),
        }),
      ],
      selectOptions: {
        noneSelectedText: i18n.t("chooseStatus"),
      },
      values: [
        { name: i18n.t("Active"), id: 1 },
        { name: i18n.t("Hidden"), id: 0 },
      ],
    },
  ]
};
