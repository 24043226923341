<template>
  <crud-form
    :schema="schema"
    :model-id="id"
    :path="'tag'"
    :form-title="$t('editTag')"
    :redirect-route="'TagList'"
    :extra-query-string="'join=attributes&join=attributes.enums'"
  />
</template>

<script>
  import CrudForm from "../../components/CrudForm";
  import { schema } from './schema/tag'

  export default {
    name: 'UpdateTag',
    components: {
      CrudForm
    },
    props: ['id'],
    data: () => {
      return {
        schema,
      }
    },
  }
</script>

<style lang="scss">
  @import "./styles/tag.scss";
</style>
